import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  ServiceBlock,
} from '../../../../Components/BasicComponents/BasicComponents';
import styles from './ServicesBlock.module.css';
import nodata from '../../../../Imgs/nodata.png';
import Imgs from '../../../../Imgs/Imgs';
import { useServicesStore } from '../../ServicesStore';
import { useDB } from 'utils/DB';
import { useTranslation } from 'react-i18next';
import {TARIFFS, withTariffGuard} from '../../../../utils/tariff.utils';

function ServicesBlock(props) {
  const { t } = useTranslation();
  let {
    service,
    services,
    categories,
    getSpecialistServices,
    setServicesBlockSection,
    addCategoryInput,
    servicesBlockSection,
    getSpecialistCategories,
    updateSpecialistCategories,
    deleteCategoryInput,
    saveCategoriesButton,
    categoryInputHandler,
    showAddServiceWindow,
    selectService,
    serviceType,

    groupService,
    groupServices,
    getSpecialistGroupServices,
    selectGroupService,
    showAddGroupServiceWindow,
  } = useServicesStore();

  useEffect(() => {
    async function af() {
      await getSpecialistCategories();
      await getSpecialistServices();
      await getSpecialistGroupServices();
    }
    af();
  }, []);

  let zaglushka = (
    <div className={styles.img_container}>
      <img src={nodata} width={'220px'} alt="" />
      <div className={styles.nodata_title}>{t('no_services')}</div>
    </div>
  );

  //Services

  if (servicesBlockSection == 'services') {
    return (
      <div className={styles.container}>
        <div className={styles.inner_container}>
          <div
            style={{ fontSize: '24px', fontWeight: 'bold', cursor: 'pointer' }}
          >
            {' '}
            {`${t('my_services') }`}
            <span
              onClick={() => {
                setServicesBlockSection('categories');
              }}
              style={{ color: '#D0D5D9', fontSize: '24px', cursor: 'pointer' }}
            >
              {' '}
              &nbsp; {t('categories')}
            </span>
          </div>

          <ServiceTypeChanger />

          {serviceType == 'Індивідуальні' ? (
            <>
              <div className={styles.services_container}>
                {services.length == 0 ? zaglushka : <></>}

                {services.map((el) => {
                  return (
                    <ServiceBlock
                      onClick={() => {
                        selectService(el._id);
                      }}
                      id={el._id}
                      name={el.name}
                      duration={`${el.hours} ${t('hours')} ${el.minutes} ${t('minutes')}`}
                      price={el.price}
                      style={
                        service._id == el._id
                          ? { backgroundColor: '#FABF7D66' }
                          : {}
                      }
                    />
                  );
                })}
              </div>

              <Button
                name={t('add_service')}
                style={{
                  marginBottom: '50px',
                  backgroundColor: '#ffff',
                  color: '#FF8A35',
                  border: '1px solid #FF8A35',
                }}
                onClick={showAddServiceWindow}
              />
            </>
          ) : (
            <>
              <div className={styles.services_container}>
                {groupServices.length == 0 ? zaglushka : <></>}
                {groupServices.map((el) => {
                  return (
                    <ServiceBlock
                      onClick={() => {
                        selectGroupService(el._id);
                      }}
                      id={el._id}
                      name={el.name}
                      hasGroup={true}
                      duration={`${el.hours} ${t('hours')} ${el.minutes} ${t('minutes')}`}
                      price={el.price}
                      style={
                        groupService._id == el._id
                          ? { backgroundColor: '#FABF7D66' }
                          : {}
                      }
                    />
                  );
                })}
              </div>

              <Button
                name={t('add_group_service')}
                style={{
                  marginBottom: '50px',
                  backgroundColor: '#ffff',
                  color: '#FF8A35',
                  border: '1px solid #FF8A35',
                }}
                onClick={showAddGroupServiceWindow}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  //Сategories

  if (servicesBlockSection == 'categories') {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.inner_container}>
            <div
              onClick={() => {
                setServicesBlockSection('services');
              }}
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: '#D0D5D9',
                cursor: 'pointer',
              }}
            >
              {`${t('my_services') }`}
              <span
                style={{ color: 'black', fontSize: '24px', cursor: 'pointer' }}
              >
                {' '}
                &nbsp; {t('categories')}
              </span>
            </div>

            <div className={styles.services_container}>
              {categories.map((value, i) => {
                return (
                  <Category
                    value={value}
                    onChange={(e) => {
                      categoryInputHandler(e, i);
                    }}
                    onClick_delete={() => {
                      deleteCategoryInput(i);
                    }}
                  />
                );
              })}

              <div
                style={{ color: 'coral', marginTop: '5px' }}
                onClick={addCategoryInput}
              >
                {' '}
                + {t('add_category')}
              </div>
            </div>

            {saveCategoriesButton ? (
              <Button
                name={t('save_changes')}
                onClick={updateSpecialistCategories}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default ServicesBlock;

//Допоміжні компоненти

export function Category(props) {
  const { t } = useTranslation();
  
  return (
    <>
      <div className={styles.category_container}>
        <Input
          placeholder={t('category_name')}
          onChange={props.onChange}
          value={props.value}
        />
        <Imgs img={'delete'} width={'30px'} onClick={props.onClick_delete} />
      </div>
    </>
  );
}

export function ServiceTypeChanger() {
  const { t } = useTranslation();
  let { serviceType, changeServiceType } = useServicesStore();

  return (
    <>
      <div style={{ cursor: 'pointer' }} className={styles.service_type}>
        <span
          onClick={() => {
            changeServiceType('Індивідуальні');
          }}
          style={serviceType == 'Індивідуальні' ? {} : { color: '#D0D5D9' }}
        >
          👤 {t('individual')}
        </span>{' '}
        &nbsp; &nbsp;
        <span
          onClick={withTariffGuard([TARIFFS.PREMIUM, TARIFFS.TRIAL], () => {
            changeServiceType('Групові');
          })}
          style={serviceType == 'Групові' ? {} : { color: '#D0D5D9' }}
        >
          {' '}
          👥 {t('groups')}
        </span>
      </div>
    </>
  );
}
