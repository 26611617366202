import React, { useState } from 'react';
import Imgs from '../../Imgs/Imgs';
import styles from './Header.module.css';
import profilePhoto from '../../Imgs/profilePhoto.png';
import support from '../../Imgs/Desktop menu/support.png';
import settings from '../../Imgs/settings.png';
import exit from '../../Imgs/Desktop menu/exit.png';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import notify from '../../Imgs/notify.png';

import { useDB } from 'utils/DB';
import { Badge } from 'rsuite';
import { NotificationButton } from 'Components/NotificationButton/NotificationButton';
import { useTranslation } from 'react-i18next';
import { PayNotify } from 'Pages/Payments/Components/PayNotify/PayNotify';
import {withTariffGuard, TARIFFS} from '../../utils/tariff.utils';
import { classNames } from 'utils/lib/classNames/classNames';

function Header(props) {
  let navigate = useNavigate();
  const { t } = useTranslation();
  let [mobileMenu, setMobileMenu] = useState('closed');

  let url = new URL(window.location.href);
  let path = url.pathname;
  let [menuState, setMenuState] = useState('none');
  let { specialist, deleteDB } = useDB();

  //Відкриття/закриття меню
  function menuHandler() {
    if (menuState == 'block') {
      setMenuState('none');
      return;
    }
    setMenuState('block');
  }

  //! Рендеринг

  // Для клієнтів
  if (props.user == 'client') {
    if (props.platform == 'mobile') {
      return (
        <>
          <div
            style={{ justifyContent: 'center' }}
            className={styles.client_header_container}
          >
            <div className={styles.client_header_logo}>
              <Imgs planguinLogoWidth={'167px'} img={'planguinLogo'} />
            </div>
            <div className={styles.notification}></div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className={styles.client_header_container}>
          <div className={styles.client_header_logo}>
            <Imgs planguinLogoWidth={'167px'} img={'planguinLogo'} />
          </div>
          <div className={styles.notification}></div>
        </div>
      </>
    );
  }

  //Desktop
  if (props.platform == 'desktop') {
    return (
      <>
        <PayNotify />

        <div className={styles.desktop_header_container}>
          <div className={styles.logo}>
            <Imgs img={'planguinLogo'} planguinLogoWidth={'220px'} />
          </div>

          <div className={styles.links}>
            <div
              onClick={() => {
                navigate('/calendar');
              }}
              style={path == '/calendar' ? { color: '#FF8A35' } : {}}
            >
              {t('my_records')}
            </div>
            <div
              onClick={() => {
                navigate('/profile/specialist');
              }}
              style={path == '/profile/specialist' ? { color: '#FF8A35' } : {}}
            >
              {t('profile')}
            </div>
            <div
              onClick={() => {
                navigate('/specialist/services');
              }}
              style={path == '/specialist/services' ? { color: '#FF8A35' } : {}}
            >
              {t('services')}
            </div>
            <div
              onClick={() => {
                navigate('/clients');
              }}
              style={path == '/clients' ? { color: '#FF8A35' } : {}}
            >
              {t('clients')}
            </div>
            <div
              className={classNames("", {[styles.disabled]: withTariffGuard([TARIFFS.PREMIUM, TARIFFS.TRIAL], null, {hasBoolean: true})()})}
              onClick={withTariffGuard([TARIFFS.PREMIUM, TARIFFS.TRIAL],() => navigate('/profile/casa'))}
              style={{ 
                position: 'relative', color: '#979797',
                ...(path === '/profile/casa' ? { color: '#FF8A35' } : {})
              }}
            >
              <div className={styles.inDevelopment}>Pro</div>
              {t('fin_cash_register')}
            </div>
          </div>

          <div className={styles.account_container}>
            <NotificationButton />

            <div i className={styles.account} onClick={menuHandler}>
              <div className={styles.photo}>
                <img
                  style={{ borderRadius: '50%' }}
                  src={
                    specialist?.photo != '0' ? specialist?.photo : profilePhoto
                  }
                  alt=""
                  width={'100px'}
                />
              </div>
              <div
                className={styles.specialist}
              >{`${specialist?.name} ${specialist?.surname}`}</div>
              <div className={styles.down_arrow}>
                <Imgs img={'downArrow'} />
              </div>
            </div>
          </div>

          <div className={styles.desktop_menu} style={{ display: menuState }}>
            <Menu_Item
              type={'emoji'}
              emoji={'📲'}
              title={t('notifications')}
              onClick={() => {
                window.open('/notifications', '_self');
              }}
            />

            <Menu_Item
              type={'emoji'}
              emoji={'📊'}
              title={
                <Badge content="Pro" color="orange">
                  {t('statistics')} &nbsp;&nbsp;
                </Badge>
              }
              onClick={
                withTariffGuard([TARIFFS.PREMIUM, TARIFFS.TRIAL],() => window.open('/stats', '_self'))
              }
              disabled={withTariffGuard([TARIFFS.PREMIUM, TARIFFS.TRIAL], null, {hasBoolean: true})()}
            />

            <Menu_Item
              type={'emoji'}
              emoji={'👩‍💻'}
              title={t('support')}
              onClick={() => {
                navigate('/support');
              }}
            />
            
            <Menu_Item
              type={'emoji'}
              emoji={'💰'}
              icon={notify}
              title={t('payment')}
              onClick={() => {
                window.open('/payments', '_self');
              }}
            />

            <Menu_Item
              icon={settings}
              title={t('settings')}
              onClick={() => {
                navigate('/profile/settings');
              }}
            />

            {/* <Menu_Item icon={help} title={'Допомога'}/> */}
            {/* <Menu_Item icon={payment} title={'Оплата'}/> */}
           
            <Menu_Item
              icon={exit}
              title={t('logout')}
              onClick={() => {
                Cookies.remove('sToken', {
                  domain: process.env.REACT_COOKIE_DOMAIN,
                });
                deleteDB();
                window.location.href = '/login';
              }}
            />
          </div>
        </div>
      </>
    );
  }

  //Mobile

  if (mobileMenu == 'closed') {
    return (
      <>
        <PayNotify type={'mobile'} />

        <div className={styles.mobile_header_container}>
          <div
            onClick={() => {
              window.location.href = '/profile/specialist/';
            }}
            className={styles.logo}
          >
            <Imgs planguinLogoWidth={'167px'} img={'planguinLogo'} />
          </div>
          <div
            className={styles.menu}
            onClick={() => {
              menuState == 'block'
                ? setMenuState('none')
                : setMenuState('block');
            }}
          >
            <Imgs img={'menu'} />
          </div>
          <DropdownMenu
            onClose={() => {
              setMenuState('none');
            }}
            display={menuState}
          />
        </div>
      </>
    );
  }
}

export default Header;

export function MobileHeader(props) {
  let navigate = useNavigate();

  if (props.type == 2) {
    return (
      <>
        <div className={styles.mobileHeaderContainer2}>
          <Imgs
            img={'back_arrow'}
            onClickB={() => {
              navigate('/more');
            }}
          />
          <div className={styles.header_title}>{props.title}</div>
          <div></div>
        </div>
      </>
    );
  }

  return (
    <>
      <PayNotify type={'mobile'} />
      <div className={styles.mobileHeaderContainer}>
        <Imgs planguinLogoWidth={'167px'} img={'planguinLogo'} />
        <div className={styles.account_container}>
          <NotificationButton />
          <Imgs
            onClick={() => {
              window.location.href = '/calendar/?adda';
            }}
            img={'plus'}
          />
        </div>
      </div>
    </>
  );
}

function DropdownMenu(props) {
  let navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.mobile_menu} style={{ display: props.display }}>
        <div
          onClick={props.onClose}
          style={{
            color: 'coral',
            fontWeight: 'bold',
            position: 'relative',
            left: '245px',
            top: '20px',
          }}
        >
          X
        </div>
        <div className={styles.links_container}>
          <div
            className={styles.link}
            onClick={() => {
              window.location.href = '/calendar';
            }}
          >
            {t('my_records')}
          </div>
          <div
            className={styles.link}
            onClick={() => {
              window.location.href = '/profile/specialist/?page="shedule"';
            }}
          >
            {t('work_schedule_2')}
          </div>
          <div
            className={styles.link}
            onClick={() => {
              window.location.href = '/specialist/services';
            }}
          >
            {t('services')}
          </div>
          <div
            className={styles.link}
            onClick={() => {
              window.location.href = '/profile/specialist/?page="profile"';
            }}
          >
            {t('my_profile')}
          </div>
          <div
            className={styles.link}
            onClick={() => {
              window.location.href = '/notifications';
            }}
          >
            {t('notifications')}
          </div>
          <div
            className={styles.link}
            onClick={() => {
              window.location.href = '/payments';
            }}
          >
            {t('payment')}
          </div>
        </div>

        <hr></hr>
        <Menu_Item
          icon={support}
          title={t('support')}
          onClick={() => {
            navigate('/support');
          }}
        />
        {/* <Menu_Item icon={help} title={'Допомога'}/> */}
        {/* <Menu_Item icon={payment} title={'Оплата'}/> */}
        <Menu_Item
          icon={exit}
          title={t('logout')}
          onClick={() => {
            Cookies.remove('sToken', {
              domain: process.env.REACT_COOKIE_DOMAIN,
            });
            window.location.href = '/login';
          }}
        />
      </div>
    </>
  );
}

function Menu_Item(props) {
  if (props.type == 'emoji') {
    return (
      <>
        <div
          onClick={props.onClick}
          className={classNames(styles.menu_item, {[styles.disabled]: props?.disabled})}
          style={props.style}
        >
          <div className={styles.menu_item_icon}>{props.emoji}</div>
          <div className={styles.menu_item_title}> {props.title}</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        onClick={props.onClick}
        className={styles.menu_item}
        style={props.style}
      >
        <div className={styles.menu_item_icon}>
          <img src={props.icon} alt="" />
        </div>
        <div className={styles.menu_item_title}> {props.title}</div>
      </div>
    </>
  );
}
